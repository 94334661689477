<template>
  <main class="main">
    <page-slider
      :slides="parentPageData.acf.banner_group.slides"
      v-if="parentPageData && parentPageData.acf && parentPageData.acf.show_banner"
    ></page-slider>
    <div class="py-4" v-else></div>

    <b-container fluid="xl" v-show="hasData">
      <div class="meta d-flex my-5 align-items-start">
        <span class="date" v-if="pageData && pageData.post_date">{{ pageData.post_date }}</span>
        <badges
          :categories="pageData.categories"
          :markets="pageData.markets"
          v-if="pageData.categories"
        ></badges>
      </div>

      <b-row>
        <b-col
          :class="pageData.acf.related_items ? 'col-xl-8' : 'col-xl-12'"
          v-if="pageData && pageData.acf"
          class="col-12 col-xl-8 col-sm-12 order-first"
        >
          <section-title class="mb-5 text-left">{{
              pageData.title
            }}</section-title>
        </b-col>
        <b-col
          xl="4"
          class="col-related order-2 col-lg-4 heading"
          v-if="pageData && pageData.acf && pageData.acf.related_items"
        >
          <h3
            class="h3 text-center mt-2"
            v-if="
              hasParentData &&
              parentPageData.acf.section_2 &&
              parentPageData.acf.section_2.label
            "
            v-text="parentPageData.acf.section_2.label"
          ></h3>
        </b-col>
        <b-col class="col-12 col-xl-8 col-sm-12 order-3 article">
          <divider />
          <div
            class="excerpt my-5 text-bold text-small"
            v-html="pageData.acf.description_intro"
            v-if="pageData && pageData.acf && pageData.acf.description_intro"
          ></div>

          <div class="d-flex justify-content-center">
            <img
              v-if="pageData && pageData.acf && pageData.acf.additional_image_top"
              :src="
                backendResourceDomain +
                pageData.acf.additional_image_top.sizes.event
              "
              alt=""
              class="img-fluid mb-4"
            />
          </div>

          <div
            class="text text-small mb-4"
            v-html="pageData.acf.description"
            v-if="pageData && pageData.acf && pageData.acf.description"
          ></div>

          <div class="d-flex justify-content-center">
            <img
              v-if="pageData && pageData.acf && pageData.acf.additional_image"
              :src="
                backendResourceDomain +
                pageData.acf.additional_image.sizes.event
              "
              alt=""
              class="img-fluid"
            />
          </div>

          <div class="row mb-5">
            <div
              class="
                col-12 col-md-6
                d-flex
                flex-wrap
                justify-content-center
                mt-auto
              "
            >
              <product-wave
                :attachments="pageData.acf.files"
                v-if="pageData && pageData.acf && pageData.acf.files"
                class="my-5"
              />
            </div>
          </div>
        </b-col>
        <b-col
          xl="4"
          class="col-related order-4 col-lg-4"
          v-if="pageData && pageData.acf && pageData.acf.related_items"
        >
          <related-news-swiper
            :slides="pageData.acf.related_items"
            :endpoint="this.$route.meta.slug"
          />
        </b-col>
      </b-row>

      <b-container fluid="xl">
        <socials
          :title="pageData.acf.section_seo.meta_title"
          :url="pageData.url"
          :description="pageData.acf.section_seo.meta_description"
          v-if="!isLoading && pageData.url && pageData.acf.section_seo && pageData.acf.section_seo.meta_title"
        />
      </b-container>
      <router-link
        class="
        btn btn-primary
        p-4
        text-small text-uppercase
        w-100
        text-bold
        redirect-btn
        my-5
      "
        :to="parentPageData.acf.section_2.back_button.url"
        v-if="
        hasParentData &&
        parentPageData.acf.section_2 &&
        parentPageData.acf.section_2.back_button &&
        parentPageData.acf.section_2.back_button.url &&
        parentPageData.acf.section_2.back_button.anchor
      "
      >
        <span>{{ parentPageData.acf.section_2.back_button.anchor }}</span>
      </router-link>
    </b-container>

  </main>
</template>

<script>
import axios from "@/axios";
import PageSlider from "../components/PageSlider";
import SectionTitle from "../components/SectionTitle";
import Divider from "../components/Divider";
import ProductWave from "../components/ProductWave";
import Socials from "../components/Socials";
import RelatedNewsSwiper from "../components/RelatedNewsSwiper";
import Badges from "../components/Badges";

export default {
  props: ["id"],
  components: {
    PageSlider,
    SectionTitle,
    Socials,
    Divider,
    ProductWave,
    RelatedNewsSwiper,
    Badges,
  },
  data: function () {
    return {
      isLoading: true,
      hasData: false,
      hasParentData: false,
      backendResourceDomain: "",
      currentPageSlug: "home",
      pageData: [],
      parentPageData: [],
    };
  },
  metaInfo() {
    if (!this.isLoading && this.pageData.acf.section_seo) {
      return {
        title: this.pageData.acf.section_seo.meta_title,
        meta: [
          {
            name: "description",
            content: this.pageData.acf.section_seo.meta_description,
          },
          {
            name: "keywords",
            content: this.pageData.acf.section_seo.meta_keywords,
          },
        ],
      };
    }
  },
  async created() {
    this.getParentPageDataBySlug();
    this.getNews();
  },
  watch: {
    $route(to, from) {
      this.getParentPageDataBySlug();
      this.getNews();
    },
  },
  methods: {
    getParentPageDataBySlug() {
      axios
        .get("custom-pages/" + this.$route.meta.parent_page_slug)
        .then((response) => {
          // console.log("custom", response);
          this.parentPageData = response.data;
          this.hasParentData = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getNews() {
      // First, get an ID of service article used as Support page content
      let serviceId = null;
       await axios.get("custom-pages/support") // ID of service
        .then((response) => {
          serviceId = response.data.acf.section_5_content.service_button.service_page.ID;
        })
        .catch((error) => {
          console.log(error);
        });
       // Get content data of chosen service page
      await axios
        .get(this.$route.meta.endpoint + "/" + serviceId) // ID of service
        .then((response) => {
          this.pageData = response.data;
          this.hasData = true;
          this.isLoading = false;
          this.pageData.url = window.location.href; // this.$route.fullPath
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/config";
.meta {
  font-family: "NeoSansPro Medium";
  font-style: normal;
  .date {
    font-family: "NeoSansPro Light";
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }
}

/*
.badge {
  color: #ffffff;
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 10px;

  &-maritime {
    background-color: $blue-20;
  }
  &-offshore {
    background-color: $blue-10;
  }
  &-industrial {
    background-color: $red;
  }
}
*/

.col-related {
  position: relative;
  .h3 {
    color: $blue-20;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
}

.swiper {
  margin-top: -50px;
}

@media (min-width: 768px) {
  .text {
    -webkit-columns: 40px 2;
    /* Chrome, Safari, Opera */
    -moz-columns: 60px 2;
    /* Firefox */
    columns: 60px 2;
  }
}

@media (max-width: 1200px) {
  .swiper {
    position: relative;
    margin-top: 50px;
  }
  .heading {
    order: 3;
  }
  .article {
    order: 2;
  }
}
</style>
